.intro-game-level-wrapper {
  background-size: cover;
  background-position: center center;
}
.intro-game-level-wrapper.blurred:before {
  display: block;
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(61.33deg, rgba(0, 0, 0, 0.1) 57.6%, rgba(255, 255, 255, 0.1) 99.18%);
  backdrop-filter: blur(5px);  
}
.intro-game-level-page-content {
  flex-direction: row;
  gap: 46px;
}
.intro-game-level-page-content.vertical {
  flex-direction: column;
}
.intro-game-level-page-footer {
  flex: 0 0 272px;
}