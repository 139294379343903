.transactions-decisions-wrapper {
  background: #fff;
  box-shadow: 0px 20px 32px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 30px 60px 29px;
  position: relative;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 480px;
}
.transactions-decisions-wrapper:before,
.transactions-decisions-wrapper:after {
  display: block;
  content: '';
  width: 100px;
  height: 85px;
  background-size: 100% 100%;
  position: absolute;
}
.transactions-decisions-wrapper:before {
  top: 0;
  left: 0;
  background-image: linear-gradient(to bottom right, #F9C151 50%, transparent 50%);
  border-top-left-radius: 16px;
}
.transactions-decisions-wrapper:after {
  bottom: 0;
  right: 0;
  background-image: linear-gradient(to top left, #F9C151 50%, transparent 50%);
  border-bottom-right-radius: 16px;
}

.transactions-decisions-pagination {
  display: flex;
  margin: 0 0 35px;
  padding: 0;
  list-style: none;
  gap: 12px;
  align-self: flex-start;
  margin-left: 100px;
}
.transactions-decisions-pagination li {
  width: 18px;
  height: 18px;
  background: rgba(0, 27, 59, 0.15);
  border-radius: 50%;
}
.transactions-decisions-pagination li.active {
  background: #F9C151;
}

.transactions-decisions-active-page {
  font-weight: 700;
  font-size: 30px;
  line-height: 49.86px;
  color: #ECA427;
  background: #FFF8EB;
  border-radius: 50%;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}

.transaction-description-question-wrapper {
  width: 100%;
  color: rgba(0, 27, 59, 0.94);
  position: relative;
}
.transaction-description-question-wrapper .block-item-points {
  right: 50%;
  top: auto;
  bottom: -40px;
  transform: translateX(50%);
}
.transaction-description {
  margin: 0 0 30px;
  text-align: left;
  font-weight: 500;
  font-size: 25px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 65px;
}
.transaction-description.first-decision {
  margin-bottom: 16px;
}
.transaction-question {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  margin: 0 0 15px;
}

.transactions-answer-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  margin-bottom: 35px;
}
.transactions-answer-options button {
  padding: 15.6px 16px;
  border-radius: 4px;
  outline: 1px solid rgba(0, 27, 59, 0.2);
  background: rgba(0, 27, 43, 0.02);
  white-space: nowrap;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  min-width: 160px;
  color: rgba(0, 27, 59, 0.6);
  border: 3px solid transparent;
}
.transactions-answer-options button.active {
  position: relative;
  background: rgba(0, 27, 59, 0.08);
  border-color: rgba(0, 27, 59, 0.2);
}
.transactions-answer-options button.correct {
  background: rgba(234, 243, 236, 1);
  border: 1px solid #2DA548
}
.transactions-answer-options button.incorrect {
  background: #FAEDED;
  border: 1px solid #EC5E59;
}
.transactions-answer-options button.active:after {
  display: block;
  content: '';
  background-size: 16px 16px;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 4px;
  right: 4px;
}
.transactions-answer-options button.correct:after {
  background: url('./images/icon-correct-option.svg') no-repeat center center;
}
.transactions-answer-options button.incorrect:after {
  background: url('./images/icon-incorrect-option.svg') no-repeat center center;
}

.transactions-decisions-wrapper .button-navi {
  margin-top: 0;
  padding: 8px 7.5px;
  min-width: 65px;
}
.transactions-decisions-wrapper .button-navi:disabled {
  cursor: auto;
}
.transactions-decisions-wrapper .button-navi.loading:before {
  width: 11.2px;
  height: 11.2px;
  content: '';
  display: block;
  border: 3px solid #f3f3f3;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}
.transactions-decisions-wrapper .button-navi.button-next {
  padding: 8px 16px;
}


.expense-recognition-modal {
  position: relative;
  padding: 43px 49px;
  align-items: flex-start;
  row-gap: 0;
}
.expense-recognition-modal .close-button {
  width: 7px;
  height: 7px;
  background: url('./images/icon-close.svg') no-repeat center center;
  background-size: 7px 7px;
  position: absolute;
  top: 18px;
  right: 22px;
  padding: 0;
}
.expense-recognition-modal h3 {
  border-radius: 10px;
  background: #EC5E59;
  color: #fff;
  font-weight: 700;
  font-size: 30px;
  line-height: 28px;
  width: 100%;
  text-align: center;
  padding: 12.5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.expense-recognition-modal p {
  margin: 10px 0;
  line-height: 28px;
}
.expense-recognition-modal a {
  color: #598CED;
  font-weight: 700;
  text-decoration: underline;
}
.expense-recognition-modal ul {
  margin: 0;
  padding-left: 25px;
}
.expense-recognition-modal ul li {
  line-height: 28px;
}
.expense-recognition-modal ul li::marker {
  font-size: 10px;
}
.expense-recognition-modal .close-button-bottom {
  margin-top: 200px;
  align-self: flex-end;
  border-radius: 10px;
  padding: 8px 36px;
}