.summary-pages-analysis {
  min-width: 650px;
}
.analysis-block:not(:first-child) {
  margin-top: 20px;
}
.analysis-block-title-wrapper {
  font-family: 'Mukta Vaani';
  color: #3A507B;
  text-align: center;
  margin: 0 0 33px;
}
h3.analysis-block-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.analysis-block-subtitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #3A507B;
  margin: 0;
}