body {
  /* min-height: 800px; */
  font-family: 'Mukta Vaani', sans-serif;
}

button {
  background: transparent;
  box-shadow: none;
  border: none;
  cursor: pointer;
  font-family: 'Mukta Vaani', sans-serif;
}

input, textarea {
  font-family: 'Mukta Vaani', sans-serif;
}

ol.decimal {
  list-style-type: decimal;
}
ol.lower-alpha {
  list-style-type: lower-alpha;
}

.Root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* min-height: 800px; */
  position: relative;
  overflow: hidden;
}

.Root * {
  box-sizing: border-box;
}

.Root-topbar {
  height: 60px;
  background-color: grey;
  text-align: center;
}

.page-header {
  display: flex;
  align-items: center;
  padding: 40px 64px 0;
}

.site-logo {
  width: 60px;
}

.page-header span {
  margin-left: 16px;
  font-size: 17px;
  font-weight: 700;
  line-height: 25px;
}

.page-content {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 30px 15px;
  color: #2F4166;
}

.page-title {
  font-size: 36px;
  font-weight: 700;
  line-height: 45px;
  margin-top: 47px;
  margin-bottom: 16px;
  max-width: 900px;
  text-align: center;
}

.page-subtitle {
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 8px;
  max-width: 900px;
  text-align: center;
}

.bold {
  font-weight: 700;
}

.game-level-wrapper {
  height: 100vh;
  /* min-height: 800px; */
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  z-index: 1;
}
/* .game-level-wrapper::-webkit-scrollbar {
  width: 10px;
  position: relative;
  z-index: 1;
} */

.container {
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
}

.container.columns {
  display: flex;
}

.main-section {
  display: flex;
  flex: 1;
  background: #FAFAFA;
  justify-content: center;
}

.content {
  flex: 1;
  background: transparent;
  padding-top: 32px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.align-center {
  text-align: center;
}

.falling-area-container {
  padding: 7px 7px 40px;
  height: 100%;
}

.falling-area {
  position: relative;
  width: 100%;
  display: flex;
  height: 100%;
  /* overflow: hidden; */
  background: url('./images/falling-area-bottom-pattern.svg') no-repeat bottom center;
}

.page-content h3 {
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  margin: 0 0;
}

.button-navi {
  font-family: 'Mukta Vaani';
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  padding: 8px 43px;
  background: #2A4169;
  color: #fff;
  border-radius: 100px;
  margin-top: 6px;
  text-decoration: none;
  display: inline-block;
}
.button-navi:disabled {
  color: rgba(0, 27, 59, 0.38);
  background: rgba(0, 27, 59, 0.15);
}
.button-blue {
  font-family: 'Mukta Vaani';
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  padding: 8px 28px;
  background: #5C91F5;
  border-radius: 5px;
  color: #fff;
}

.button-red {
  background: #EC5E59;
  color: #fff;
  display: inline-block;
  text-decoration: none;
}

.button-logout {
  font-family: 'Mukta Mahee';
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-decoration: underline;
  text-decoration-thickness: .25px;
  color: #fff;
  position: relative;
  z-index: 10;
}

.red-highlight {
  color: #FE7474;
  font-weight: 700;
}

.form {
  width: 100%;
}
.form-row {
  display: flex;
  column-gap: 30px;
}
.form-row label {
  font-family: 'Mukta Mahee';
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 11px;
  display: inline-block;
}
.input-container {
  flex: 1;
}
.input-container input {
  padding: 11px 17px;
  font-family: 'Mukta Mahee';
  font-size: 17px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.01em;
  border-radius: 4px;
  box-shadow: 0px 2px 8px 0px #00000026;
  border: none;
  width: 100%;
}
.button-submit {
  font-family: 'Mukta Vaani';
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  margin-top: 53px;
  padding: 11px 60px;
  border-radius: 5px;
  align-self: flex-end;
  margin-left: auto;
  text-decoration: none;
}
.button-submit.loading:before {
  content: '';
  display: block;
  border: 4px solid #f3f3f3;
  border-top: 4px solid transparent;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}
.warning {
  color: #EC5E59;
  font-size: 12px;
  margin-top: 2px;
  min-height: 20px;
}
.negative, .red {
  color: #EC5E59;
}

.background-overlay {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  align-items: center;
  justify-content: center;
  z-index: 1;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media screen and (max-width: 768px) {
  .page-header {
    padding: 20px;
  }
  .site-logo {
    width: 50px;
  }
}

@media screen and (max-width: 480px) {
  .page-content {
    justify-content: flex-start;
  }
  .form-row {
    flex-direction: column;
    row-gap: 20px;
  }
}