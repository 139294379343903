.adjustment-content-container .content-wrapper {
  max-width: 1100px;
}

.add-assignment {
  display: flex;
  justify-content: flex-end;
}
.button-add-assignment {
  display: flex;
  column-gap: 10px;
  padding: 12px 15px;
}
.button-add-assignment span {
  font-size: 40px;
  line-height: 16px;
  font-weight: 275;
}
.instructor-assignments hr {
  border-top: 1px solid #D9DDE2;
  margin: 10px 0;
}
.instructor-add-assignment-form > hr:first-child {
  margin-top: 0;
}



.instructor-input-container {
  display: flex;
  color: rgba(0, 27, 59, 0.94);
  column-gap: 20px;
  align-items: center;
  margin-bottom: 12px;
}
.instructor-input-container label {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  flex-basis: 100px;
  min-width: 100px;
  flex-grow: 0;
  flex-shrink: 0;
}
.instructor-input-container label.full-width {
  flex-basis: auto;
}
.instructor-input-container input,
.instructor-input-container textarea {
  border: 1px solid #D5D9DD;
  padding: 8px 10px;
  color: rgba(0, 27, 59, 0.94);
  font-size: 16px;
  line-height: 24px;
  flex-basis: 100%;
}
.instructor-input-container textarea {
  resize: none;
  height: 100px;
}
.instructor-input-container textarea::-webkit-scrollbar {
  width: 7px;
}

.instructor-input-container textarea::-webkit-scrollbar-thumb {
  background: #A4A3A3;
  border-radius: 4px;
}

.instructor-input-container textarea::-webkit-scrollbar-track {
  background: transparent;
}

.instructor-input-container .instructor-date-selector-label {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: rgba(0, 27, 59, 0.6);
}
.instructor-input-container .instructor-datetime-selector {
  border: 1px solid #BDC0CC;
  border-radius: 4px;
  padding: 5px 31px 5px 8px;
  font-size: 14px;
  width: 260px;
  background: url('./images/icon-calendar.svg') no-repeat center right 9px;
}
.instructor-input-container .instructor-datetime-selector:active,
.instructor-input-container .instructor-datetime-selector:focus,
.instructor-input-container .instructor-datetime-selector:hover {
    border: 1px solid #0095e6;
    box-shadow: 0 0 0 3.2px rgba(32, 162, 232, .25);
    outline: none;
}

.instructor-input-container .rdtPicker {
  border-radius: 8px;
  width: 427px;
  padding: 7.3px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}

.instructor-input-container .rdtPicker table {
  border-spacing: 0;
  border-collapse: collapse;
  color: #494E50;
}

.instructor-date-selector-wrapper .rdtPicker thead .rdtSwitch {
  font-size: 16px;
  line-height: 150%;
  font-weight: 500;
}

.instructor-date-selector-wrapper .rdtPicker thead tr:first-child th {
  border-bottom: none;
  border-radius: 8px;
  border-bottom: 3px solid #fff;
}

.instructor-date-selector-wrapper .rdtPicker thead tr:first-child th.rdtNext,
.instructor-date-selector-wrapper .rdtPicker thead tr:first-child th.rdtPrev {
  font-size: 27px;
  color: #494E50;
}

.instructor-date-selector-wrapper .rdtPicker thead tr:nth-child(2) {
  background: #F5F7FA;
  color: #424242;
}

.instructor-date-selector-wrapper .rdtPicker td,
.instructor-date-selector-wrapper .rdtPicker th {
  height: 40px;
  font-size: 14px;
  font-weight: 400;
}

.instructor-date-selector-wrapper .rdtPicker td {
  border-radius: 8px;
}

.instructor-date-selector-wrapper .rdtPicker td {
  border-radius: 8px;
}

.instructor-date-selector-wrapper.date-selector-from .rdtPicker td {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.instructor-date-selector-wrapper.date-selector-to .rdtPicker td {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.instructor-date-selector-wrapper .rdtPicker td.rdtActive,
.instructor-date-selector-wrapper .rdtPicker td.rdtActive:hover {
  background: #01A2F9;
}

.instructor-date-selector-wrapper .rdtPicker .rdtCounter .rdtBtn {
  color: #01A2F9;
}

.instructor-date-selector-wrapper .rdtPicker .rdtCounter .rdtCount {
  height: auto;
  font-size: 14px;
}

.instructor-date-selector-wrapper .rdtPicker tfoot tr {
  border-top: 3px solid #fff;
}

.instructor-date-selector-wrapper .rdtPicker tfoot .rdtTimeToggle {
  font-size: 0;
}

.instructor-date-selector-wrapper .rdtPicker tfoot .rdtTimeToggle:after {
  display: block;
  overflow: hidden;
  content: 'Select Time';
  font-size: 14px;
}

.instructor-add-assignment-form hr {
  border-top: 1px solid #D9DDE2;
  margin: 26px 0 31px;
}

.assignment-game-buttons {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.assignment-game-buttons button {
  border: 1px solid #D5D9DD;
  padding: 3px 10px;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  border-radius: 5px;
  white-space: nowrap;
}
.assignment-game-buttons button.button-game[disabled]:hover {
  cursor: auto;
}
.assignment-game-buttons button.button-game:not([disabled]):hover,
.assignment-game-buttons button.button-game.active {
  background: #2DA548;
  color: #fff;
}
.button-save-assignment {
  align-self: flex-end;
  margin-left: auto;
}
.button-save-assignment[disabled] {
  background: rgba(0, 27, 59, 0.08);
  color: rgba(0, 27, 59, 0.38);
  cursor: auto;
}
.button-save-assignment.loading:before {
  content: '';
  display: block;
  border: 3px solid #f3f3f3;
  border-top: 3px solid transparent;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}


.instructor-set-levels-container h3 {
  color: rgba(0, 27, 59, 0.94);
  font-size: 18px;
  line-height: 24px;
  margin-top: 0;
}
.instructor-assignment-level-tabs {
  display: flex;
  padding-left: 120px;
  column-gap: 25px;
  position: relative;
}
.instructor-assignment-level-tabs:after {
  display: block;
  content: '';
  border-bottom: 1px solid #D5D9DD;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.instructor-assignment-level-tabs .assignment-tab-header,
.instructor-assignment-level-tabs .assignment-tab-add-level {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: rgba(0, 27, 59, 0.94);
  padding: 3px 7px;
  border: 1px solid #D5D9DD;
  position: relative;
  z-index: 1;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  cursor: pointer;
}
.instructor-assignment-level-tabs .assignment-tab-header.active {
  border-bottom-color: #fff;
  justify-content: space-between;
}
.instructor-assignment-level-tabs .assignment-tab-header.active span {
  flex: 1;
  text-align: center;
}
.instructor-assignment-level-tabs .assignment-edit-scenario-level {
  width: 14px;
  height: 14px;
  background: url('./images/icon-edit-navi.svg') no-repeat center center;
  background-size: 14px 14px;
  margin-right: auto;
}
.instructor-assignment-level-tabs .assignment-delete-scenario-level {
  width: 14px;
  height: 15px;
  background: url('./images/icon-delete-navi.svg') no-repeat center center;
  background-size: 14px 15px;
}
.instructor-assignment-level-tabs .assignment-tab-add-level img {
  margin-left: 6px;
}
.instructor-level-items-modal {
  padding: 46px 37px;
  position: relative;
  align-items: flex-start;
  row-gap: 21px;
  flex-basis: 1300px;
  height: 850px;
}
.instructor-level-items-modal h3 {
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 0;
}
.instructor-level-items-modal .close-button {
  width: 24px;
  height: 24px;
  background: url('./images/icon-close.svg');
  background-size: 24px 24px;
  position: absolute;
  top: 29px;
  right: 63px;
}
.instructor-level-items-modal p {
  font-size: 14px;
  font-weight: 300;
  color: #2A4169;
  margin-bottom: 0;
}

.instructor-level-items-filter-container {
  display: flex;
  width: 100%;
  column-gap: 20px;
  justify-content: space-between;
  overflow: auto;
  height: 100%;
}
.instructor-level-items-filter-container::-webkit-scrollbar {
  width: 3px;
}
.instructor-level-items-filter-container::-webkit-scrollbar-thumb {
  background: rgba(0, 27, 59, 0.94); 
}
.instructor-level-items-filter-column {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  /* flex-basis: calc(100% / 6); */
  padding-right: 9px;
}
.instructor-level-items-filter-column h4 {
  font-size: 18px;
  line-height: 24px;
  color: rgba(0, 27, 59, 0.94);
  margin-top: 0;
  margin-bottom: 20px;
  text-decoration: underline;
}
.instructor-level-items-filter-column:first-child {
  padding-right: 40px;
  margin-right: 20px;
  position: relative;
}
.instructor-level-items-filter-column:first-child:after {
  display: block;
  content: '';
  width: 4px;
  background: #D5D9DD;
  position: absolute;
  top: 40px;
  bottom: 0;
  right: 0;
  opacity: 0.24;
}
.instructor-level-items-filter-column:first-child h4 {
  text-decoration: none;
}
.instructor-level-items-filter-column:last-child {
  margin-right: auto;
}
.instructor-level-items-modal .save-account-items-selection {
  margin-top: 20px;
  align-self: flex-end;
}

.account-item-checkbox input,
.account-item-radio input {
  position: absolute;
  cursor: pointer;
  height: 0;
  width: 0;
  margin: 0;
}
.account-item-checkbox label,
.account-item-radio label {
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}
.account-item-radio label {
  padding-left: 3px;
  white-space: nowrap;
}
.account-item-checkbox label:before {
  display: block;
  content: '';
  min-width: 15px;
  width: 15px;
  height: 15px;
  border: 2px solid #2A4169;
  border-radius: 3px;
  margin-right: 10px;
  box-sizing: border-box;
}
.account-item-radio label:before {
  display: block;
  content: '';
  box-shadow: 0 0 0 2px #2A4169;
  width: 12px;
  min-width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}
.account-item-checkbox input:checked + label:before {
  background: url('./images/icon-checkbox-checked.svg') no-repeat center center;
  background-size: 15px 15px;
}
.account-item-radio input:checked + label:before {
  border: 2px solid #fff;
  width: 9.4px;
  height: 9.4px;
  min-width: 9.4px;
  background: #2A4169;
}
.account-item-children {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-top: 9px;
  margin-bottom: 15px;
}

.instructor-level-saved-accounts {
  display: flex;
  padding: 25px 0 0 120px;
  column-gap: 50px;
}
.instructor-level-saved-accounts p {
  margin-bottom: 25px;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
}
.instructor-level-saved-accounts-column h4 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-decoration: underline;
  margin: 0 0 11px;
}
.instructor-level-saved-accounts-column .instructor-level-saved-accounts-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.instructor-level-saved-accounts-column .instructor-level-saved-accounts-list li {
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  color: rgba(0, 27, 59, 0.94);
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  column-gap: 4.5px;
}
.instructor-level-saved-accounts-column .instructor-level-saved-accounts-list li:before {
  display: block;
  content: '';
  width: 20px;
  height: 20px;
  background: #2A4169;
  min-width: 20px;
}