.hint-container {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 25px;
  z-index: 10;
  min-width: 400px;
  display: flex;
  justify-content: center;
}
.hint-container.left {
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  padding-top: 0;
  padding-left: 22px;
  justify-content: flex-start;
}
.hint-container.right {
  top: 50%;
  right: 100%;
  left: auto;
  transform: translateY(-50%);
  padding-top: 0;
  padding-right: 22px;
  justify-content: flex-end;
}
.hint {
  width: fit-content;
  z-index: 100;
  padding: 12px 16px;
  background: #fff;
  box-shadow: 0px 10px 16px 0px rgba(0, 0, 0, 0.1), 0px 0px 6px 0px rgba(0, 0, 0, 0.08);
  position: relative;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  font-family: 'Inter';
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: rgba(0, 27, 59, 0.94);
}
.hint:before {
  content: '';
  position: absolute;
  top: -13px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 18px solid #fff;
}
.hint-container.left .hint:before {
  top: 50%;
  left: -21px;
  transform: translateY(-50%);
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-right: 18px solid #fff;
}
.hint-container.right .hint:before {
  top: 50%;
  right: -21px;
  left: auto;
  transform: translateY(-50%);
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 18px solid #fff;
}
.hint button {
  background: #2A4169;
  color: #fff;
  padding: 7px 32px;
  font-family: 'Mukta Vaani';
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  border-radius: 4px;
  margin-top: 12px;
  align-self: flex-end;
}