.balance-sheet {
  position: relative;
  padding: 40px 20px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.84);
  width: 320px;
  min-height: 480px;
  transition: transform 1s ease;
  transform: translateX(0%);
  align-self: flex-start;
}
.balance-sheet.should-slide-in {
  transform: translateX(300%);
}

.balance-sheet h2 {
  font-size: 21px;
  font-weight: 700;
  line-height: 28px;
  margin: 0 0 40px;
  color: #5C91F5;
}

.balance-sheet-items {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.balance-sheet-items li {
  background: #FCFDFF;
  padding: 12px 12px 12px 36px;
  font-family: 'Mukta Vaani';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: rgba(0, 27, 59, 0.94);
  border-radius: 8px;
}
.balance-sheet-row {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
}
.balance-sheet-row.has-children {
  position: relative;
  margin-bottom: 10px;
}
.balance-sheet-row.has-children:before {
  display: block;
  content: '';
  position: absolute;
  top: 50%;
  left: -11px;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 5px;
  background: url('./images/icon-arrow-up.svg') no-repeat center center;
  background-size: 10px 5px;
}
.balance-sheet-row.child {
  color: rgba(0, 27, 59, 0.6);
}