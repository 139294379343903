.data-table-container {
  margin: 55px 15px 20px;
  border-radius: 4px;
  border: 1px solid #D5D9DD;
}
.data-table {
  border-radius: 4px;
  border-spacing: 0;
  display: block;
  overflow: auto;
  white-space: nowrap;
  max-height: 490px;
  /* transform: rotateX(180deg); */
}
.data-table::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.data-table::-webkit-scrollbar-thumb {
  background: #CED1D6;
  border-radius: 2px;
}
.data-table thead {
  /* transform: rotateX(180deg); */
}
.data-table thead tr {
  background: #F5F6F7;
  box-shadow: 0px -1px 0px 0px #DEE0E3 inset;
}
.data-table thead th {
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  padding: 16px 60px 16px 35px;
  position: relative;
  cursor: pointer;
}
.data-table thead th:before {
  display: block;
  content: '';
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 35px
}
.data-table thead th.active:before {
  background: url('./images/icon-arrow-sort.svg') no-repeat center center;
  background-size: 20px 20px;
  transform: rotateX(180deg) translateY(50%);
}
.data-table thead th.active.desc:before {
  transform: rotateX(0) translateY(-50%);
}
.data-table thead th:after {
  display: block;
  content: '';
  width: 1px;
  height: 16px;
  background: #CED1D6;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.data-table thead th:last-child:after {
  display: none;
}
.data-table tbody tr {
  background: #FAFBFC;
  box-shadow: 0px -1px 0px 0px #ECEDEF inset;
}
.data-table tbody tr td {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 12px;
  text-align: center;
}

.table-controls {
  display: flex;
  justify-content: flex-end;
  padding: 14px 10px;
  align-items: center;
}
.table-controls {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.table-controls select {
  margin-left: 15px;
  margin-right: 20px;
  border: 1px solid #D5D9DD;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding: 4.5px 27px 4.5px 12px;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background: url('./images/icon-arrow-select.svg') no-repeat right 13px center;
  background-size: 8px 5px;
}
.table-controls button {
  background: url('./images/icon-arrow-button.svg') no-repeat center center;
  background-size: 9px 14px;
  width: 27px;
  height: 24px;
}
.table-controls button.prev {
  transform: rotate(180deg);
  margin: 0 20px;
}
.table-controls button:disabled {
  opacity: 0.4;
  cursor: initial;
}