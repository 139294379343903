.page-content-login {
  background: url('./images/login.png') no-repeat calc(50% + 415px) -15px;
  background-size: 688px 588px;
}
.login-page-container {
  max-width: 660px;
}
.login-page-container h1 {
  font-family: 'Mukta Mahee';
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  text-align: left;
  color: rgba(47, 65, 102, 1);
  max-width: 600px;
  margin-bottom: 20px;
}
.login-page-container h3 {
  text-align: left;
  align-self: flex-start;
}
.login-page-container .form {
  margin-top: 64px;
}
.login-warning {
  font-family: 'Mukta Mahee';
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  color: #5C6C7F;
  display: inline-block;
  text-align: right;
  width: 100%;
  margin-top: 42px;
}


.transition-page-content .page-subtitle {
  margin-bottom: 0;
}
.transition-page-content .page-title {
  margin-top: 26px;
}
.transition-image {
  width: 100%;
  max-width: 410px;
}
.transition-image.transition-image-instructor {
  max-width: 768px;
}
.transition-under-the-image {
  font-family: 'Nova Slim';
  font-size: 30px;
  font-weight: 400;
  line-height: 37.5px;
  color: rgba(0, 27, 59, 0.94);
  margin-top: 67px;
}

.intro-video-wrapper {
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  row-gap: 55px;
}
.intro-button {
  border: 1px solid #D5D9DD;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  background: #fff;
  color: #3A507B;
  font-size: 24px;
  font-weight: 700;
  line-height: 16px;
  padding: 17px 15px;
  min-width: 150px;
  margin-left: auto;
}