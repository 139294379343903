.transactions-income-statement {
  position: relative;
  padding: 40px 20px;
  border-radius: 16px;
  background: #FFF8EB;
  box-shadow: 0px 10px 16px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);
  width: 320px;
  min-height: 480px;
  height: 100%;
  transition: transform 1s ease;
  transform: translateX(0%);
}
.transactions-income-statement.should-slide-in {
  transform: translateX(300%);
}
.transactions-income-statement.income-statement-balance-sheet.should-slide-in {
  transform: translateX(-300%);
}
.transactions-income-statement h2 {
  font-family: 'Mukta Vaani';
  font-size: 21px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  padding: 0 30px;
  margin: 0 0 40px;
}
.income-statement-items {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.income-statement-items li {
  background: #FCFDFF;
  padding: 12px 12px 12px 36px;
  font-family: 'Mukta Vaani';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: rgba(0, 27, 59, 0.94);
  border-radius: 8px;
}
.income-statement-items li.net-profits {
  padding: 12px;
  background: transparent;
}

.income-statement-row {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  width: 100%;
}
.income-statement-row.has-children {
  position: relative;
  margin-bottom: 10px;
}
.income-statement-row.has-children:before {
  display: block;
  content: '';
  position: absolute;
  top: 50%;
  left: -11px;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 5px;
  background: url('./images/icon-arrow-up.svg') no-repeat center center;
  background-size: 10px 5px;
}
.income-statement-row.child {
  color: rgba(0, 27, 59, 0.6);
}

.income-statement-net-profits {
  display: flex;
  justify-content: space-between;
  margin: 8px 0 0;
  padding: 12px;
  font-family: 'Mukta Vaani';
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: rgba(0, 27, 59, 0.94);
}