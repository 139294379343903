.timer-wrapper {
  justify-content: flex-end;
  width: 32%;
  justify-content: flex-end;
  width: 32%;
  display: flex;
}
.timer {
  width: 120px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid rgba(0, 27, 59, 0.20);
  background: rgba(0, 27, 59, 0.04);
  display: flex;
  align-items: center;
  justify-content: center;

  color: rgba(0, 27, 59, 0.94);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}