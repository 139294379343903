.falling-block-container {
  position: absolute;
}

.brick {
  border-radius: 4px;
  border: 1.5px solid rgba(0, 27, 59, 0.94);
  background: #FFF;
  box-shadow: 4px 4px 0px 0px #D5D9DD;
  padding: 6px 12px;
  cursor: pointer;

  color: rgba(0, 27, 59, 0.94);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  position: relative;
  z-index: 1;
  text-align: center;
}

.brick:hover {
  color: #fff;
  border-color: #2A4169;
  background: #2A4169;
  z-index: 10;
}