.loader {
  height: 4px;
  background: #FDD0D4;
  border-radius: 4px;
  max-width: 864px;
  width: 100%;
  margin: 35px 0;
  overflow: hidden;
}
.loader:before {
  content: '';
  display: block;
  height: 100%;
  width: 50%;
  background: #EC5E59;
  border-radius: 4px;
  animation: loaderAnimation 2s linear infinite;
}
@keyframes loaderAnimation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(200%);
  }
}
.user-onboarding-picture {
  width: 200px;
  height: 200px;
  background: rgba(255, 123, 2, 0.5) url('./images/male-octopus-hat.png') no-repeat center center;
  background-size: 133px;
  border-radius: 8px;
  margin-bottom: 40px;
}
.enter-alias-container {
  margin-top: 40px;
}
.alias-row {
  display: flex;
  column-gap: 40px;
}
.enter-alias-container input {
  width: 240px;
  padding: 6px 17px;
}
.enter-alias-container button {
  border-radius: 100px;
  font-family: "Mukta Vaani";
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  padding: 12px 60px;
  margin-top: 0;
}

.enter-alias-container .button-submit.loading:before {
  width: 11.2px;
  height: 11.2px;
  border-width: 3px;
}


@media screen and (max-width: 480px) {
  .enter-alias-container {
    margin-top: 20px;
  }
  .alias-row {
    flex-direction: column;
    row-gap: 20px;
  }
}