.games-assignments-list {
  margin-top: 35px;
}
.games-assignment-type {
  margin-bottom: 45px;
}
.games-assignment-type-heading {
  padding: 5px 7px;
  display: flex;
  align-items: center;
  column-gap: 15px;
  min-height: 50px;
}
.games-assignment-type-heading h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 16px;
  margin: 0;
}
.games-assignment-type.classification .games-assignment-type-heading {
  background: rgba(253, 149, 149, 0.5);
}
.games-assignment-type.classification .games-assignment-type-heading img {
  width: 43px;
}
.games-assignment-type.classification .games-assignment-type-heading h3 {
  color: #2A4169;
}
.games-assignment-type.equation .games-assignment-type-heading {
  background: rgba(249, 193, 81, 0.5);
}
.games-assignment-type.equation .games-assignment-type-heading img {
  width: 41px;
}
.games-assignment-type.equation .games-assignment-type-heading h3 {
  color: rgba(0, 27, 59, 0.6);
}
.games-assignment-type.normal .games-assignment-type-heading {
  background: rgba(112, 161, 255, 0.5);
}
.games-assignment-type.normal .games-assignment-type-heading img {
  width: 35px;
}
.games-assignment-type.normal .games-assignment-type-heading h3 {
  color: #2A4169;
}
.games-assignment-type.principles .games-assignment-type-heading {
  background: #92DBE1;
}
.games-assignment-type.principles .games-assignment-type-heading img {
  width: 35px;
}
.games-assignment-type.principles .games-assignment-type-heading h3 {
  color: #2A4169;
}

.games-assignment-item {
  padding: 10px 25px 10px 15px;
  display: flex;
  align-items: center;
  column-gap: 20px;
  border: 1px solid #D5D9DD;
  border-bottom: none;
}
.games-assignment-item:last-child {
  border-bottom: 1px solid #D5D9DD;
}
.games-assignment-item .edit-assignment {
  background: url('./images/icon-edit-grey.svg') no-repeat center center;
  background-size: 25px 24px;
  width: 25px;
  height: 24px;
}
.games-assignment-item-info-heading {
  display: flex;
  align-items: center;
  column-gap: 4px;
}
.games-assignment-item .games-assignment-item-info-heading h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: #2A4169;
  margin: 0;
}
.games-assignment-item .delete-assignment {
  width: 20px;
  height: 20px;
  background: url('./images/icon-delete-grey.svg') no-repeat center center;
  background-size: 20px 20px;
}
.games-assignment-item-info-footer {
  margin-top: 11px;
}
.games-assignment-item-info .games-assignment-item-info-footer p {
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  margin: 0;
  color: #2A4169;
}
.games-assignment-item-info-footer p span {
  font-weight: 500;
}
.games-assignment-item-info-footer a {
  color: inherit;
  text-decoration: none;
}
.games-assignment-item-info-footer button {
  line-height: inherit;
  padding: 0;
}

.games-assignment-item .preview-assignment {
  margin-left: auto;
  min-width: 90px;
  margin-top: 0;
  font-size: 14px;
  padding: 8px 15px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}

.games-assignment-info-container {
  position: relative;
  display: flex;
}
.games-assignment-item .assignment-info-icon {
  width: 20px;
  height: 20px;
  background: url('./images/icon-info.svg') no-repeat center center;
  background-size: 13px;
  padding: 0;
}
.games-assignment-info {
  position: absolute;
  left: 30px;
  z-index: 1;
  min-width: 450px;
  transform: translateY(-50%);
  box-shadow: 0px 20px 32px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  background: transparent;
  border-radius: 20px;
}
.games-assignment-info h5 {
  background: #ECF6F7;
  font-family: 'Inter';
  font-size: 28px;
  font-weight: 600;
  line-height: 42px;
  padding: 7px 15px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  text-align: center;
  margin: 0;
  color: rgba(0, 27, 59, 0.94);
}
.games-assignment-info.no-description h5 {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.games-assignment-info-container .games-assignment-info p {
  background: #fff;
  margin: 0;
  color: rgba(0, 27, 59, 0.94);
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  padding: 16px 38px 32px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}