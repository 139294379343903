.assignments-page-wrapper {
  /* display: flex;
  flex-direction: column;
  height: 100%; */
}
.assignments-page-wrapper.subpages-layout-content h1 {
  font-size: 30px;
  font-weight: 700;
  line-height: 66px;
  color: #2A4169;
  text-transform: none;
}