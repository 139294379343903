.intro-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 100;
  overflow: hidden;
}
.intro-side {
  position: relative;
  display: flex;
  width: 50%;
  transition: transform 2s ease;
  transform: translateX(0%);
  width: calc(50% + 105px);
  position: absolute;
  top: 0;
  bottom: 0;
}
.intro-side-left {
  color: #2F4166;
  font-family: 'Mukta Mahee';
  background: url('./images/left-background.png') no-repeat center center;
  background-size: cover;
  left: 0;
}
.intro-side-left-slide-out {
  animation: slideLeftIntro 1s ease;
}
.intro-side-left-join {
  animation: slideLeftIntroJoin 1s ease .4s backwards;
}
.intro-content-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.intro-content-container h1 {
  font-size: 80px;
  font-weight: 700;
  line-height: 100px;
  margin: 0 0 18px;
}
.intro-content-container h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  margin: 0;
}
.intro-side-left .intro-content {
  width: 410px;
}
.intro-side-left .intro-content-container {
  width: calc(100% - 140px);
  height: 100%;
  position: relative;
}
.intro-side-right {
  font-family: 'Inter';
  color: rgba(0, 27, 59, 0.94);
  background: url('./images/right-background.png') no-repeat center center;
  background-size: cover;
  right: 0;
}
.intro-side-right-slide-out {
  animation: slideRightIntro 1s ease;
}
.intro-side-right-join {
  animation: slideRightIntroJoin 1s ease .4s backwards;
}
.intro-side-right .intro-content-container {
  width: calc(100% - 140px);
  align-self: flex-end;
  margin-left: auto;
  height: 100%;
}
.intro-side-right .intro-content {
  width: 475px;
}
.intro-side-right span {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: black; }
}

@keyframes slideLeftIntro {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes slideRightIntro {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(calc(100% + 140px));
  }
}

@keyframes slideLeftIntroJoin {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

/* Right side joining animation */
@keyframes slideRightIntroJoin {
  0% {
    transform: translateX(calc(100% + 140px));
  }
  100% {
    transform: translateX(0%);
  }
}