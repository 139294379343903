.analysis {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  font-family: 'Mukta Vaani', sans-serif;
  min-width: 730px;
}
.analysis-header {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 32px 50px 25px;
  background: #2A4169;
}
.analysis-header h2 {
  margin: 0;
  color: #fff;
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
}

.analysis-header-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 15px;
  margin-top: 16px;
}
.analysis-header-buttons button {
  border-bottom: 2px solid transparent;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  padding: 0 0 5px;
  color: #fff;
}
.analysis-header-buttons button.active {
  border-color: #fff;
}

.analysis-body {
  background: #fff;
  padding: 22px 33px 87px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.analysis.decisions .analysis-body {
  padding: 36px 90px 55px 80px;
}

.analysis-bars {
  margin: 0;
  padding: 0 90px 0 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
.analysis-bars li {
  display: flex;
  column-gap: 24px;
  align-items: center;
}
.analysis-bars .analysis-title {
  color: #2F4166;
  flex: 0 0 140px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: right;
}
.analysis-bars .analysis-score-wrapper {
  display: inline-block;
  width: 100%;
}
.analysis-bars .analysis-score {
  font-size: 17px;
  font-weight: 800;
  line-height: 24px;
  height: 24px;
  text-align: right;
  color: #fff;
  padding: 0 8px;
  display: inline-block;
  background: #52C5CD;
  min-width: 6%;
  position: relative;
}
.analysis-bars .analysis-score span {
  position: absolute;
  left: calc(100% + 5px);
  color: #2A4169;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}


.analysis-body .analysis-body-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  color: rgba(0, 27, 59, 0.94);
  margin-bottom: 22px;
  padding: 0 5px;
}

.analysis-questions-decisions {
  margin: 0;
  padding: 0;
  list-style: none;
}
.analysis-questions-decisions li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;
  margin-bottom: 8px;
}
.question-decision-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FAFAFA;
  border-radius: 4px;
  min-width: 400px;
  padding: 2px 16px;
}
.question-decision-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-right: 16px;
  min-width: 32px;
  display: inline-block; 
}
.question-decision-status {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;  
}
.question-decision-status.correct {
  color: #2DA548; 
}
.question-decision-status.incorrect {
  color: #EC5E59; 
}
.question-decision-info a {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: rgba(0, 27, 59, 0.94);
}
.question-decision-activity {
  padding: 2.5px 8px;
  gap: 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 23px;
  color: #55B4BC;
  background: #ECF6F7;
}