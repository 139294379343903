.modal-overlay {
  display: flex;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  align-items: center;
  justify-content: center;
}
.modal-overlay.hidden {
  background: transparent;
  z-index: 1;
}
.modal {
  display: flex;
  flex-basis: 600px;
  width: 600px;
  min-height: 320px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 20px 32px 0px rgba(0, 0, 0, 0.10), 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  padding: 20px 50px 27px;
}
.modal.small {
  width: 300px;
  flex-basis: 300px;
  flex-direction: row;
  height: fit-content;
  min-height: auto;
}
.modal-center {
  display: flex;
  /* flex-basis: 600px; */
  flex: 1;
  width: 600px;
  justify-content: center;
  align-items: center;
  background: #FFF;

  color: rgba(0, 27, 59, 0.94);
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}
.modal-center.vertical {
  flex-direction: column;
}
.modal-counter {
  display: none;
}
.modal-counter.visible {
  display: block;
}


.modal-content {
  padding: 50px 60px 0;
}

.modal-content p {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  color: linear-gradient(0deg, rgba(0, 27, 59, 0.94), rgba(0, 27, 59, 0.94));
  margin: 0 0 25px;
}

.modal-content b {
  color: #5C91F5;
  font-weight: 600;
}

.level-start-wrapper {
  height: 142px;
  width: 100%;
  background: #ECF6F7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.level-start-content {
  color: rgba(0, 27, 59, 0.94);
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.level-start-button {
  cursor: pointer;
  display: flex;
  width: 420px;
  height: 40px;
  border-radius: 4px;
  background: #52C5CD;
  align-items: center;
  justify-content: center;

  color: #FFF;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}

.level-start-description {
  padding-top: 35px;
  color: rgba(0, 27, 59, 0.94);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  max-width: 420px;
}

.level-completed-wrapper {
  height: 240px;
  width: 100%;
  background: #ECF6F7;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.level-completed-score {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 240px;
  height: 160px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: #52C5CD;
}

.level-completed-score-text1 {
  color: #FFF;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.level-completed-score-text2 {
  color: #FFF;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.level-completed-content {
  max-width: 160px;
  color: rgba(0, 27, 59, 0.60);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 200%;
}

.level-completed-content b {
  color: rgba(0, 27, 59, 0.94);
  font-size: 28px;
}

.next-button {
  display: flex;
  width: 160px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #2A4169;
  cursor: pointer;

  color: #FFF;
  font-family: 'Mukta Vaani';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;
}