.transaction-records-wrapper {
  font-family: 'Mukta Vaani';
  width: 1000px;
  position: relative;
  align-self: flex-start;
}
.transaction-records-header {
  background: #142D4A;
  padding: 15px 50px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  color: #fff;
  position: relative;
}
.transaction-records-header h2 {
  margin: 0 0 7px 0;
  font-size: 23px;
  line-height: 36px;
}
.transaction-records-header p {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
}


.transaction-records-body {
  background: #F5F6F7;
  padding: 15px 35px;
  min-height: 250px;
}
.transaction-records-body .top-options-row {
  display: flex;
  justify-content: space-between;
}
.transaction-records-body .top-options-row h4 {
  width: 254px;
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  color: rgba(0, 27, 59, 0.94);
  margin: 0;
}
.transaction-records-body .records-row {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  gap: 5px;
}

.transaction-record-drop-area-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
}
.transaction-record-drop-area {
  font-size: 14px;
  font-weight: 500;
  line-height: 23px;
  color: rgba(0, 27, 59, 0.38);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 254px;
  /* max-width: 292px; */
  max-width: 280px;
  gap: 8px;
  position: relative;
  background: #fff;
  border: 1px solid #DCE0E4;
  padding: 8px;
  flex-wrap: wrap;
}
.transaction-record-drop-area.empty {
  background: transparent;
  border: 1px dashed rgba(0, 27, 59, 0.38);
  padding: 10.5px;
}

.transaction-records-footer {
  background: #fff;
  padding: 12px 35px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
.drag-drop-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #5C91F5;
  background: #EBF2FF;
  border: 1px solid #91B6FD;
  border-radius: 2px;
  padding: 2px 10px;
  display: inline-block;
  position: relative;
  z-index: 1;
}
.transaction-records-footer .item-rows-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  row-gap: 15px;
  padding-right: 10px;
}
.transaction-records-footer .options-item-row {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding-right: 25px;
}
.transaction-records-footer .options-item-row-hint-anchor {
  position: relative;
}

.transaction-records-footer .options-item-row .transaction-records-option-item {
  position: relative;
  z-index: 1;
}
.transaction-records-option-item-wrapper {
  position: relative;
}
.transaction-record-drop-area .transaction-records-option-change-wrapper {
  order: 1;
}
.transaction-record-drop-area .transaction-records-option-core-wrapper {
  order: 2;
  max-width: 110px;
}
.transaction-record-drop-area .transaction-records-option-value-wrapper {
  order: 3;
}
.transaction-record-drop-area .transaction-records-option-transaction-wrapper {
  order: 4;
  flex-basis: calc(100% + 16px);
  margin: 0 -8px -8px -8px;
  align-self: flex-end;
}
.transaction-records-option-item {
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
  color: rgba(0, 27, 59, 0.94);
  padding: 5px 11px;
  border: 1px solid #DCE0E4;
  border-radius: 4px;
  box-shadow: 0px 2px 0px 0px #DCE0E4;
  min-width: 48px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}
.transaction-records-option-item.option-item-debit {
  color: rgba(0, 27, 59, 0.94);
  background: #B9D9DE;
  box-shadow: inset 0px 1.5px 0px 0px #D3F0F2, 0px 2px 0px 0px #DCE0E4;
  border: 1px solid #52C5CD;
}
.transaction-records-option-item.option-item-credit {
  color: #55B4BC;
  background: #2A4169;
  box-shadow: 0px 2px 0px 0px #2A4169;
  border: 1px solid #2A4169;
}
.transaction-records-option-item:hover {
  background: #2A4169;
  color: #fff;
}
.transaction-records-option-value-wrapper .transaction-records-option-item:hover {
  background: #fff;
  color: #2A4169;
}
.transaction-records-option-item.incorrect {
  color: #EC5E59;
  border-color: #EC5E59;
  background: #FAEDED;
  box-shadow: 0px 2px 0px 0px #EC5E59;
}
/* .transaction-record-drop-area .transaction-records-option-item {
  max-width: 100px;
} */
.transaction-record-drop-area .transaction-records-option-core-wrapper .transaction-records-option-item span {
  white-space: nowrap;      /* Prevent text from wrapping */
    overflow: hidden;         /* Hide the overflow text */
    text-overflow: ellipsis;  /* Show ellipsis for overflow text */
    max-width: 100%;          /* Set a maximum width, you can adjust this as needed */
    display: block;
}
.transaction-record-drop-area .transaction-records-option-transaction-wrapper .transaction-records-option-item {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.transaction-records-option-item img {
  width: 13.33px;
  height: 16px;
}
.transaction-records-option-item span {
  display: inline-flex;
}

.submit-transaction-records-wrapper {
  position: absolute;
  right: 40px;
  bottom: 32px;
}
.submit-transaction-records {
  margin: 0;
  padding: 8px 15px;
  min-width: 80px;
}
.submit-transaction-records.disabled {
  color: rgba(0, 27, 59, 0.38);
  background: rgba(0, 27, 59, 0.15);
}
.submit-transaction-records.loading:before {
  width: 11.2px;
  height: 11.2px;
  content: '';
  display: block;
  border: 3px solid #f3f3f3;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}
.submit-hint-wrapper {
  position: absolute;
  right: 74px;
  bottom: 32px;
  z-index: 1;
}