.alert-overlay {
  display: flex;
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  align-items: center;
  justify-content: center;
}
.alert {
  display: flex;
  flex-basis: 416px;
  width: 416px;
  min-height: 168px;
  flex-direction: column;
  row-gap: 24px;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 20px 32px 0px rgba(0, 0, 0, 0.10), 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  padding: 32px 32px 24px;
}
.alert-content-wrapper {
  display: flex;
  column-gap: 16px;
}
.alert-content h4 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: rgba(0, 27, 59, 0.94);
  margin: 0 0 8px;
}
.alert-content p {
  color: rgba(0, 27, 59, 0.94);
  margin: 0;
}
.alert-buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  column-gap: 8px;
}
.alert-buttons button {
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  padding: 6px 14px;
  font-weight: 500;
}
.alert-cancel-button {
  border: 1.5px solid #D5D9DD;
  color: rgba(0, 27, 59, 0.94);
}