.game-column-wrapper {
  width: 32%;
  display: flex;
  padding: 40px 0;
}

.game-column {
  flex-direction: column;
  flex: 1;
  padding: 0 !important;
  display: flex;
}

.game-column.left-column {
  background: #EAE4F9;
  box-shadow: 0px 10px 16px 0px rgba(0, 0, 0, 0.10), 0px 0px 6px 0px rgba(0, 0, 0, 0.08);
}
.game-column.left-column.game-type-normal {
  background: #D7F0F2;
}
.game-column.right-column {
  background: #E9EEFF;
  box-shadow: 0px 10px 16px 0px rgba(0, 0, 0, 0.10), 0px 0px 6px 0px rgba(0, 0, 0, 0.08);
}
.game-column.right-column.game-type-normal {
  background: #FBEDD5;
}

.blocks-drop-area {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}

.level-blocks-title {
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 800;
  line-height: 36px;
  text-align: center;
  margin-top: 11px;
}
.left-column .level-blocks-title {
  color: #A98DF6;
}
.left-column.game-type-normal .level-blocks-title {
  color: #55B4BC;
}
.right-column .level-blocks-title {
  color: #5C91F5;
}
.right-column.game-type-normal .level-blocks-title {
  color: #ECA427;
}
.level-blocks-children .level-blocks-title {
  width: calc(100% - 74px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  border-radius: 5px;
  margin: 13px 0;
}
.left-column .level-blocks-children .level-blocks-title {
  background: #A98DF6;
}
.right-column .level-blocks-children .level-blocks-title {
  background: #5C91F5;
}
.level-3-column .level-blocks-children .level-blocks-title {
  margin: 13px 0 0; 
}
.level-blocks-children .level-blocks-children .level-blocks-title {
  margin: 4px 0 0;
  border-radius: 0;
}
.left-column .level-blocks-children .level-blocks-children .level-blocks-title {
  background: rgba(169, 141, 246, 0.5);
}
.right-column .level-blocks-children .level-blocks-children .level-blocks-title {
  background: rgba(92, 145, 245, 0.5);
}
.level-blocks-children {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}
.level-blocks-content {
  flex: 1 1;
  padding: 39px 25px 36px 23px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
}
.level-blocks-children .level-blocks-content {
  gap: 12px;
  padding: 0 13px;
}
.level-blocks-children .level-blocks-children .level-blocks-content, .level-3-column .level-blocks-children .level-blocks-content {
  gap: 8px;
  padding: 8px;
}
.game-column > .blocks-drop-area > .level-blocks-children > .blocks-drop-area:last-child .level-blocks-content {
  padding-bottom: 13px;
}
.block-item-container {
  position: relative;
  flex: 1 1;
  max-height: 184px;
  display: flex;
}
.block-item {
  background-color: #FFFCFC;
  width: 100%;
  display: flex;
  line-height: 150%;
  font-family: Inter;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: rgba(0, 27, 59, 0.94);
  border-radius: 8px;
  font-weight: 700;
  padding: 0 4px;
  text-align: center;
  position: relative;
  transition: background-color 0.3s ease;
}
.left-column .block-item {
  box-shadow: 6px 6px 0px 0px rgba(169, 141, 246, 0.25);
}
.left-column.game-type-normal .block-item {
  box-shadow: 6px 6px 0px 0px rgba(82, 197, 205, 0.25);
}
.right-column .block-item {
  box-shadow: 6px 6px 0px 0px rgba(92, 145, 245, 0.25);
}
.right-column.game-type-normal .block-item {
  box-shadow: 6px 6px 0px 0px rgba(236, 164, 39, 0.25);
}
.level-blocks-children .block-item-container {
  flex: 0 1;
}
.level-blocks-children .block-item {
  font-size: 13px;
  font-weight: 500;
  line-height: 13px;
  justify-content: flex-start;
  height: fit-content;
  padding: 5px 4px;
  text-align: left;
  border-radius: 2px;
}
.left-column .level-blocks-children .block-item {
  box-shadow: 4px 4px 0px 0px rgba(169, 141, 246, 0.25);
}
.right-column .level-blocks-children .block-item {
  box-shadow: 4px 4px 0px 0px rgba(104, 156, 255, 0.25);
}
.left-column .level-blocks-children .level-blocks-children .block-item {
  box-shadow: 4px 4px 0px 0px rgba(169, 141, 246, 0.25);
}
.right-column .level-blocks-children .level-blocks-children .block-item {
  box-shadow: 4px 4px 0px 0px rgba(104, 156, 255, 0.25);
}
.block-item.flash-green.animate {
  animation: flashGreen 1.5s;
  color: rgba(0, 27, 59, 0.94) !important;
}
.block-item.flash-red.animate {
  animation: flashRed 1.5s forwards;
  color: rgba(0, 27, 59, 0.94) !important;
}
.block-item.correctly-placed {
  background: #EAF3EC;
}
.block-item.with-hover-effect:hover {
  background: #2A4169;
  color: #fff;
}
/* .block-item.flash-red.with-hover-effect:hover {
  animation: flashRed 1.5s forwards;
  background: #FCB7B7;
  color: rgba(0, 27, 59, 0.94);
} */

@keyframes flashGreen {
  0% { background-color: #D1E9D6; }
  100% { background-color: #FFFCFC; }
}

@keyframes flashRed {
  0%, 50% { background-color: #FCB7B7; }
  100% { background-color: #FFFCFC;}
}

.block-item-points {
  position: absolute;
  right: 17px;
  top: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color:#2DA548;
  font-family: 'Mukta Vaani';
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  animation-name: fade-in-up;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}
.block-item-points span:last-child {
  font-size: 21px;
  line-height: 28px;
}
.level-blocks-children .block-item-points, 
.level-blocks-children .block-item-points span:last-child {
  font-size: 13px;
  line-height: 13px;
  top: 0;
  animation-name: fade-in-up-small;
}

@keyframes fade-in-up {
  0%,
  10% {
    top: 14px;
    opacity: 1;
  }
  100% {
    top: -50%;
    opacity: 0;
  }
}

@keyframes fade-in-up-small {
  0%,
  10% {
    top: 0;
    opacity: 1;
  }
  100% {
    top: -70%;
    opacity: 0;
  }
}