.decision-explanation-container {
  position: relative;
  background: #fff;
  margin: 30px 40px 45px;
  padding: 28px 160px 28px 380px;
  border-radius: 20px;
  min-height: 140px;
  box-shadow: 8px 8px 0px 0px #F9C151;
}
.decision-explanation-container.correct {
  box-shadow: 8px 8px 0px 0px #2DA548;
  background: #EFF9F1;
}
.decision-explanation-container.incorrect {
  box-shadow: 8px 8px 0px 0px #FE7474;
  background: #FAEDED;
}
.decision-explanation-container .popup-messages-avatar {
  position: absolute;
  left: 178px;
  top: -34px;
}

.decision-explanation-title-container {
  display: flex;
  align-items: center;
  gap: 12px;
  font-family: 'Mukta Vaani';
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 11px;
}
.decision-explanation-container.correct .decision-explanation-title-container {
  color: #2DA548;
}
.decision-explanation-container.incorrect .decision-explanation-title-container {
  color: #EC5E59;
}
.decision-explanation-title-container img {
  width: 24px;
  height: 24px;
}

.decision-explanation-content {
  font-family: 'Mukta Vaani';
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  color: rgba(0, 27, 59, 0.94);
  margin: 0;
}
.decision-explanation-content a, 
.decision-explanation-content button {
  color: #598CED;
  font-weight: 600;
  font-size: inherit;
  text-decoration: underline;
  padding: 0;
}