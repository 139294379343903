.leaderboard-body .instructor-leaderboard {
  margin: 0;
  padding: 0 95px 0 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  max-height: 400px;
  overflow-y: auto;
}
.leaderboard-body .instructor-leaderboard::-webkit-scrollbar {
  width: 5px;
}
.leaderboard-body .instructor-leaderboard::-webkit-scrollbar-thumb {
  background: #2A4169;
}
.instructor-leaderboard li {
  display: flex;
  column-gap: 24px;
  align-items: center;
}
.instructor-leaderboard .user-name {
  color: #2F4166;
  font-size: 17px;
  font-weight: 500;
  line-height: 26px;
  flex: 0 0 140px;
  text-align: right;
}
.instructor-leaderboard .user-score-wrapper {
  display: inline-block;
  width: 100%;
}
.instructor-leaderboard .user-score {
  font-size: 17px;
  font-weight: 800;
  line-height: 24px;
  height: 24px;
  text-align: right;
  color: #fff;
  padding: 0 8px;
  display: inline-block;
  background: #FFB940;
  min-width: 6%;
  position: relative;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.instructor-leaderboard .user-score.accuracy-user-score span {
  position: absolute;
  left: calc(100% + 5px);
  font-size: 17px;
  font-weight: 800;
  line-height: 26px;
  color: #2A4169;
}
.instructor-leaderboard li:nth-child(1) .user-score,
.instructor-leaderboard li:nth-child(2) .user-score,
.instructor-leaderboard li:nth-child(3) .user-score {
  background: #5C91F5;
}
.instructor-leaderboard li:nth-child(4) .user-score,
.instructor-leaderboard li:nth-child(5) .user-score,
.instructor-leaderboard li:nth-child(6) .user-score {
  background: #A98DF6;
}




.results-leaderboard-overall-container {
  margin: 14px 0;
}
.results-leaderboard-overall-container .leaderboard {
  width: 930px;
  border-radius: 0;
  box-shadow: none;
}
.results-leaderboard-overall-container .leaderboard.small {
  width: 676px;
}
.results-leaderboard-overall-container .leaderboard-header {
  border-radius: 0;
}
.results-leaderboard-overall-container .leaderboard-body {
  padding: 60px 0 40px;
  border-radius: 0;
  display: flex;
  column-gap: 65px;
  position: relative;
}
.results-leaderboard-overall-container .leaderboard-body.without-filter {
  padding: 80px 0 60px 40px;
}
.leaderboard-filter-by {
  background: #ECF6F7;
  padding: 20px 21px;
  height: fit-content;
}
.leaderboard-filter-by p, 
.leaderboard-filter-by button {
  font-family: "Mukta Mahee";
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #2F4166;
}
.leaderboard-filter-by ul {
  padding: 0 0 0 15px;
  list-style: none;
  margin: 0;
}
.leaderboard-filter-by ul li {
  margin-bottom: 7px;
  white-space: nowrap;
}
.leaderboard-filter-by ul li:last-child {
  margin-bottom: 0;
}
.leaderboard-filter-by button {
  padding: 0;
}
.leaderboard-filter-by button.active {
  font-family: Mukta Mahee;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-decoration: underline;
}
.results-leaderboard-overall-container .leaderboard-data {
  margin: 0;
  padding: 0 95px 0 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  max-height: 465px;
  overflow-y: auto;
  font-family: "Mukta Mahee";
  width: 100%;
}
.results-leaderboard-overall-container .leaderboard-data.big-scores {
  row-gap: 50px;
}

.results-leaderboard-overall-container .leaderboard-data::-webkit-scrollbar {
  width: 5px;
}
.results-leaderboard-overall-container .leaderboard-data::-webkit-scrollbar-thumb {
  background: #2A4169;
}
.leaderboard-data li {
  display: flex;
  column-gap: 12px;
  align-items: center;
}
.leaderboard-data .user-name {
  color: #2F4166;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  flex: 0 0 145px;
  text-align: right;
}
.leaderboard-data .user-score-wrapper {
  display: inline-flex;
  width: 100%;
}
.leaderboard-data .user-score-wrapper .user-score-lines {
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  width: 100%;
  padding: 0;
  margin: 0;
}
.leaderboard-data .user-score {
  font-size: 16px;
  font-weight: 800;
  line-height: 26px;
  height: 26px;
  text-align: right;
  color: #fff;
  padding: 0 13.5px 0 8px;
  display: inline-block;
  background: #FFB940;
  min-width: 6.7%;
  position: relative;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
}
.leaderboard-data .user-score.user-score:not(.accuracy-user-score) {
  overflow: hidden;
}
.leaderboard-data.big-scores .user-score {
  line-height: 53px;
  height: 53px;
  font-size: 18px;
}
.leaderboard-data.big-scores .user-score:not(.accuracy-user-score) {
  overflow: hidden;
}
.leaderboard-data .user-score span {
  display: inline-block;
  overflow: hidden;
}
.leaderboard-data .user-score-lines .user-score {
  height: 20px;
  line-height: 20px;
}
.leaderboard-data .user-score-lines .user-score:not(.accuracy-user-score) {
  overflow: hidden;
}
.leaderboard-data .user-score.accuracy-user-score span {
  position: absolute;
  left: calc(100% + 5px);
  font-size: 14px;
  font-weight: 800;
  line-height: 26px;
  color: #2A4169;
}
.leaderboard-data.big-scores .user-score.accuracy-user-score span {
  font-size: 18px;
  line-height: 53px;
}
.leaderboard-data .user-score-lines .user-score.accuracy-user-score span {
  line-height: 20px;
}

.leaderboard-body .leaderboard-labels {
  position: absolute;
  top: 16px;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 38px;
  width: 100%;
  font-family: 'Mukta Mahee';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #2F4166;
}
.leaderboard-body .leaderboard-labels li {
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.leaderboard-body .leaderboard-labels li .label-color {
  display: block;
  content: '';
  width: 50px;
  height: 18px;
  border-radius: 20px;
}

.leaderboard-footer {
  background: #D7F0F2;
  padding: 0 60px 50px;
  font-family: 'Mukta Vaani';
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}
.leaderboard-footer ul {
  margin: 0;
}