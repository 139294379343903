.instructor-transactions-container h3 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: rgba(0, 27, 59, 0.94);
  text-decoration: underline;
  margin: 21px 0 13px;
}
.transactions-select-wrapper {
  position: relative;
}
.transactions-select-header {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1.5px solid rgba(0, 27, 59, 0.08);
  border-radius: 4px;
  padding: 13px 35px;
  background: rgba(0, 27, 59, 0.94);
}
.transactions-select-header .text-wrapper {
  background: #fff;
  padding: 8px 16px;
  border: 1.5px solid rgba(0, 27, 59, 0.08);
  border-radius: 4px;
  display: inline-block;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: rgba(0, 27, 59, 0.6);
}
.transactions-select-header .transactions-select-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: 32px;
  width: 32px;
  height: 40px;
  background: transparent url('./images/icon-arrow-down-white.svg') no-repeat center center;
  background-size: 13px 8px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.transactions-select-body {
  display: none;
}
.transactions-select-body.active {
  display: block;
  position: absolute;
  z-index: 1;
  left: 0px;
  right: 0px;
  width: 100%;
  background: rgba(42, 65, 105, 1);
  border: 1px solid rgba(42, 65, 105, 1);
  border-radius: 4px;
}
.transactions-select-body ul {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #fff;
  max-height: 300px;
  overflow-y: scroll;
}
.transactions-select-body ul::-webkit-scrollbar {
  width: 3px;
}
.transactions-select-body ul::-webkit-scrollbar-thumb {
  background: rgba(0, 27, 59, 0.94); 
}
.transactions-select-body ul li {
  cursor: pointer;
  padding: 5px 35px;
  border-bottom: 1px solid rgba(0, 27, 59, 0.94);
  font-size: 16px;
  line-height: 22px;
}
.transactions-select-body ul li:last-child {
  cursor: pointer;
  padding: 8px 35px;
  border-bottom: none;
}
.transactions-select-body ul li:hover {
  background: #fff;
  color: rgba(0, 27, 59, 0.94);
}