.score-wrapper {
  margin: 0;
  margin-right: 32%;
  flex: 1;
}

.score {
  border-radius: 400px;
  background: #BDE3FF;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  color: rgba(0, 27, 59, 0.94);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  width: fit-content;
}

.score > b {
  font-size: 20px;
  font-weight: 700;
  line-height: 100%;
  margin-left: 12px;
}