.main-section-sorting {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.main-section-sorting main.content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sorting-company-logo {
  max-width: 300px;
}
.game-footer {
  height: 160px;
  border-top: 2px solid #D5D9DD;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-view-summary {
  margin-top: 26px;
}

.summary-block-container {
  width: 600px;
  margin: 0 auto;
  font-family: 'Mukta Vaani';
}
.summary-page-title {
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  color: #5C91F5;
  margin: 0 0 8px;
}
.summary-page-description {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin: 0 0 16px;
  color: rgba(0, 27, 59, 0.6);
}
.summary-block {
  padding: 20px;
  background: #DBB7FF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  position: relative;
}
.summary-block:before,
.summary-block:after {
  display: block;
  content: '';
  position: absolute;
  top: 0;
}
.summary-block:before {
  width: 157px;
  height: 136px;
  left: 0;
  background: url('./images/summary-flags-1.png') no-repeat center center;
  background-size: 157px 136px;
}
.summary-block:after {
  width: 124px;
  height: 141px;
  right: 0;
  background: url('./images/summary-flags-2.png') no-repeat center center;
  background-size: 124px 141px;
}
.summary-block-item {
  padding: 40px;
  border-radius: 4px;
  background: #9B6FFF;
}
.summary-block-item:second-child {
  padding: 16px;
}
h3.summary-level {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #fff;
  margin: 0 0 20px;
}
.summary-rank {
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  margin: 0 0 32px;
}

.summary-stars-rating {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 40px;
}
.summary-star-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 8px;
}
.summary-star-block img {
  width: 64px;
  height: 64px;
}
.summary-star-value {
  font-size: 21px;
  font-weight: 700;
  line-height: 28px;
  color: #fff;
}

h3.summary-score {
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  color: #fff;
  text-align: center;
  margin: 0 0 10px;
}
.summary-best-score {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #fff;
  text-align: center;
  margin: 0;
}

.summary-next-button {
  margin-top: 80px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  border-radius: 100px;
  padding: 8px;
  min-width: 160px;
}

.badge-page-title {
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  color: #5C91F5;
  margin: 0 0 8px;
  text-align: center;
}
.badge-page-description {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin: 0 0 38px;
  color: rgba(0, 27, 59, 0.6);
  text-align: center;
}
.badge-container {
  position: relative;
}
.badge-info {
  position: absolute;
  top: 115px;
  text-transform: uppercase;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  max-width: 500px;
  color: #fff;
  font-family: 'Inter';
}
h3.badge-game-type {
  font-size: 38px;
  font-weight: 700;
  line-height: 44px;
  margin-bottom: 87px;
}
h2.badge-rank {
  font-size: 48px;
  font-weight: 700;
  line-height: 48px;
}

.feedback-page-content h1 {
  font-family: 'Mukta Mahee';
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  color: #2F4166;
  margin: 0 0 45px;
}
.scores-feedback-container {
  display: flex;
  column-gap: 111px;
}
.level-scores {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  font-family: 'Mukta Mahee';
}
.level-score {
  padding: 20px 20px 20px 64px;
  border-radius: 16px;
  background: #ECF6F7;
  box-shadow: 6px 6px 0px 0px rgba(82, 197, 205, 0.4);
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.level-score h3 {
  font-size: 28px;
  font-weight: 600;
  line-height: 28px;
  color: rgba(0, 27, 59, 0.94);
  margin-right: 50px;
}
.score-result-block {
  padding: 11px 24px;
  border-radius: 8px;
  row-gap: 10px;
  background: #fff;
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.score-result-block h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: rgba(0, 27, 59, 0.38);
  text-transform: uppercase;
  text-align: center;
}
.score-result-block h4:last-child {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  color: #EC5E59;
}
.score-result-block:last-child h4:last-child {
  color: #55B4BC;
}
.level-feedbacks {
  color: rgba(0, 27, 59, 0.94);
  display: flex;
  flex-direction: column;
}
.level-feedbacks h2 {
  font-family: 'Mukta Mahee';
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  margin: 0 0 24px;
}
.feedback-row {
  font-family: 'Mukta Vaani';
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
}
.feedback-row label {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 5px;
  display: inline-block;
}
.rate-game-input-container {
  margin-top: 8px;
  display: flex;
  column-gap: 10px;
}
.rate-game-input-container button {
  padding: 32px 30px 20px;
  border-radius: 8px;
  border: 1.5px solid rgba(0, 27, 59, 0.2);
  row-gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: rgba(0, 27, 59, 0.6);
  flex: 1;
  font-family: 'Mukta Vaani';
}
.rate-game-input-container button:hover,
.rate-game-input-container button.selected {
  background: #0F2846;
  border: 1.5px solid rgba(0, 27, 59, 0.2);
  color: #fff;
}
.rate-game-input-container button img {
  width: 36px;
  height: 36px;
}
.feedback-row textarea {
  border-radius: 4px;
  border: 1px solid #D5D9DD;
  color: rgba(0, 27, 59, 0.94);
  background: #FAFAFB;
  padding: 5px 12px;
  font-family: 'Mukta Vaani';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.level-feedbacks .button-submit {
  border-radius: 100px;
  font-family: "Mukta Vaani";
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  padding: 12px 60px;
  margin-top: 0;
  align-self: flex-end;
}
.level-feedbacks .button-submit.loading:before {
  width: 11.2px;
  height: 11.2px;
  border-width: 3px;
}

.final-page-content {
  flex-direction: row;
}
.final-page-content > div {
  flex: 1;
}
.final-page-left-part {
  background: url('./images/final-page.png') no-repeat center center;
  background-size: 700px 700px;
  height: 100%;
}
.final-page-right-part p {
  font-family: 'Mukta Vaani';
}
.final-page-right-part p {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #2A4169;
  margin: 0 0 12px;
}
.our-website-link {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: rgba(0, 27, 59, 0.6);
}
.social-links {
  display: flex;
  margin-top: 12px;
  column-gap: 36px;
}
.instructor-info .button-navi {
  font-weight: 700;
  font-size: 24px;
  padding: 22px 29px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 0;
}
.instructor-info ul {
  margin-top: 53px;
  margin-bottom: 76px;
}
.instructor-info li {
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 25px;
}
.instructor-info .button-red {
  font-size: 30px;
  font-weight: 700;
  line-height: 16px;
  padding: 20px 59px 14px 35px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 45px;
  position: absolute;
  right: -40px;
}