.footer-message {
  display: flex;
  flex-direction: row;
  gap: 34px;
}

.footer-message-icon {
  display: flex;
  align-items: center;
}

.footer-message-icon img {
  display: block;
  width: 30px;
  height: 30px;
}

.footer-message-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 600px;
}

.footer-message-title {
  color: rgba(0, 27, 59, 0.38);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-transform: uppercase;
}

.footer-message-description {
  color: rgba(0, 27, 59, 0.94);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  max-width: 600px;
}

.footer-message-description p {
  margin: 0;
}

.footer-message-title.m8 {
  margin-top: 8px;
}

.footer-message-description b {
  font-weight: 700;
}

@media screen and (max-width: 480px) {
  .footer-message-content {
    max-width: none;
  }
}