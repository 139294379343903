.floating-stars {
  position: absolute;
  opacity: 0;
  transition: opacity 1s ease;
}
.floating-stars.visible {
  opacity: 1;
}
.floating-stars-1 {
  top: 19px;
  left: 115px;
  width: 256px;
}
.floating-stars-2 {
  top: 140px;
  right: -66px;
  width: 443px;
}

.debrief-popup-messages-container {
  opacity: 1;
  transition: opacity 0.3s ease;
}
.debrief-popup-messages-container.hidden {
  opacity: 0;
  pointer-events: none;
}