header .menu-container {
  background: rgb(58, 80, 123);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 19px;
  padding: 15px 18px;
}
header .menu-container.expanded {
  width: 210px;
  flex: 0 0 210px;
}
header .menu-container button {
  display: flex;
  padding: 0;
}
.header-main-container {
  padding: 11px 12px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.logo a {
  display: flex;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
  color: #fff;
  align-items: center;
  column-gap: 12px;
  text-decoration: none;
}
.logo img {
  width: 32px;
}
.header-right > svg {
  cursor: pointer;
}
.user-profile-container {
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.user-profile-container img {
  cursor: pointer;
}
.notifications-wrapper {
  display: flex;
  position: relative;
}
.notifications-wrapper.active:before {
  display: block;
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #EC5E59;
  position: absolute;
  right: 3px;
  top: 0;
}
.user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.accounting-layout main {
  display: flex;
  background: #FAFAFA;
}
.accounting-leftbar {
  background: rgba(0, 27, 59, 0.08);
  padding: 19px 22px;
  height: calc(100vh - 64px);
  z-index: 2;
}
.accounting-leftbar.expanded {
  width: 210px;
  flex: 0 0 210px;
}
.accounting-leftbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.accounting-leftbar a {
  display: block;
  width: 60px;
  height: 48px;
  background-position: left 18px center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
}
.accounting-leftbar.expanded a {
  padding-left: 52px;
}
.accounting-leftbar a:hover,
.accounting-leftbar a.active {
  background-color: #c7dbff;
}
.accounting-leftbar a.dashboard {
  background-image: url('./images/icon-dashboard-navi.svg');
}
.accounting-leftbar a.settings {
  background-image: url('./images/icon-settings-navi.svg');
  background-size: 20px 20px;
}
.accounting-leftbar a.games {
  background-image: url('./images/icon-controller-navi.svg');
}
.accounting-leftbar a.insights {
  background-image: url('./images/icon-rating-navi.svg');
}

.accounting-layout .content {
  padding: 0;
  display: flex;
}