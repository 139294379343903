.sorting-items-container {
  margin-top: 27px;
}
.sorting-items-container h3 {
  font-family: 'Mukta Vaani';
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin: 0 0 17px;
}
.sorting-items-container h4 {
  margin: 100px 0 0;
  font-family: 'Mukta Vaani';
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  color: rgba(0, 27, 59, 0.6);
}
.sorting-items-container table {
  border-collapse: collapse;
}
.sorting-items-container table th,
.sorting-items-container table td {
  padding: 9px 12px;
  border-top: 1px solid rgba(0, 27, 59, 0.04);
  border-left: 1px solid rgba(0, 27, 59, 0.04);
  font-family: 'Inter';
  font-size: 14px;
  line-height: 18.2px;
}
.sorting-items-container table th {
  font-weight: 600;
  min-width: 100px;
  background: #FAFAFB;
}
.sorting-items-container table th:first-child {
  color: #fff;
  background: rgba(0, 27, 59, 0.45);
  width: 220px;
}
.sorting-items-container table th.item-parent-heading {
  font-size: 16px;
  font-weight: 700;
  line-height: 20.8px;
  text-transform: uppercase;
  padding: 2.5px 12px;
}
.instructor-normal-sorting-table th {
  width: 215px;
}
.sorting-items-container table td {
  color: #000;
}
.sorting-items-container table td:not(:first-child) {
  text-align: center;
}