.subpages-layout {
  display: flex;
  height: 100%;
  background: #fff;
}
.subpages-layout-leftbar {
  background: rgba(0, 27, 59, 0.04);
  padding: 40px;
  /* min-width: 205px; */
  width: 270px;
}
.subpages-layout-leftbar > ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.subpages-layout-leftbar > ul > li {
  position: relative;
  padding-left: 18px;
  margin-bottom: 24px;
  line-height: 24px;
}
.subpages-layout-leftbar ul li.has-children.contains-active-link {
  margin-bottom: 0;
}
.subpages-layout-leftbar > ul > li:before {
  content: '';
  position: absolute;
  left: 0;
  top: 7px;
  width: 10px;
  height: 10px;
  background-color: #2A4169;
}
.subpages-layout-leftbar > ul > li:last-child {
  margin-bottom: 0;
}
.subpages-layout-leftbar > ul > li:after {
  content: '';
  position: absolute;
  left: 5px;
  top: calc(100% + 4px);
  height: 16px;
  width: 1px;
  background: #2A4169;
}
.subpages-layout-leftbar > ul > li:last-child:after,
.subpages-layout-leftbar ul li.has-children.contains-active-link:after {
  display: none;
}
.subpages-layout-leftbar > ul > li a {
  font-family: "Mukta Vaani";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
  color: #2A4169;
  display: inline-block;
}
.subpages-layout-leftbar > ul > li a.active {
  color: rgba(0, 27, 59, 0.94);
  font-weight: 700;
  font-size: 18px;
}
.subpages-layout-leftbar ul li ul {
  list-style: none;
  padding-left: 20px;
  margin: 5px 0 11px;
  position: relative;
  display: none;
}
.subpages-layout-leftbar > ul > li a.active + ul {
  display: block;
}
.subpages-layout-leftbar ul li ul:before {
  display: block;
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  width: 1px;
  height: calc(100% - 7px);
  background: #2A4169;
}
.subpages-layout-leftbar ul li ul li {
  margin-bottom: 7px;
}
.subpages-layout-leftbar ul li ul li:last-child {
  margin-bottom: 0;
}
.subpages-layout-leftbar ul li ul li a {
  font-weight: 500;
  color: rgba(0, 27, 59, 0.38);
}
.subpages-layout-leftbar ul li ul li a.active {
  color: rgba(0, 27, 59, 0.94);
  font-weight: 500;
  font-size: 16px;
}

.subpages-layout-content {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  flex: 1;
  overflow-y: auto;
}
.subpages-layout-content .content-wrapper {
  padding: 40px;
  font-family: "Mukta Vaani";
  max-width: 890px;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.subpages-layout-content.video-welcome-content .content-wrapper {
  max-width: 950px;
}

.subpages-layout-content h2 {
  font-size: 21px;
  font-weight: 700;
  line-height: 28px;
  margin: 0 0 12px;
}
.subpages-layout-content h1 {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: rgba(0, 27, 59, 0.38);
  text-transform: uppercase;
  margin: 0 0 24px;
}
.subpages-layout-content p {
  margin: 0 0 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: rgba(0, 27, 59, 0.6);
}
.subpages-layout-content hr {
  border-top: 1px solid #EBEDF0;
  margin: 20px 0;
}
.subpages-layout-content ul {
  color: rgba(0, 27, 59, 0.6);
  margin: 5px 0;
  padding: 0 25px;
}
.subpages-layout-content .content-wrapper > ul > li {
  margin-bottom: 40px;
}
.subpages-layout-content .content-wrapper > ul > li:last-child {
  margin-bottom: 0;
}
.subpages-layout-content strong {
  color: #0F2846;
}
.subpages-layout-content ol {
  color: rgba(0, 27, 59, 0.6);
  margin: 5px 0;
  padding: 0 20px;
}

.subpages-layout-bottom-navigation {
  background: #FAFAFB;
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.subpages-layout-bottom-navigation .subpages-first-button {
  font-size: 16px;
  font-weight: 500;
  height: 32px;
  line-height: 16px;
  color: rgba(0, 27, 59, 0.6);
  font-family: "Mukta Vaani";
  background: url('./images/icon-prev.svg') no-repeat 6px center;
  padding: 0 0 0 24px;
}
.subpages-layout-bottom-navigation .subpages-second-button {
  margin-left: auto;
}
.subpages-layout-bottom-navigation .subpages-layout-pagination {
  color: rgba(0, 27, 59, 0.6);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  flex-grow: 1;
  text-align: center;
}
