.accounting-layout .insights-content-container {
  background: url('./images/insights-background.png') no-repeat top left;
  flex-grow: 1;
}
.accounting-layout .insights-content-container .content-wrapper {
  max-width: 100%;
}
.results-leaderboard-container {
  display: flex;
  align-items: flex-start;
  column-gap: 30px;
  height: 100%;
  margin-top: 35px;
}
.leaderboard-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.leaderboard-group .button-update-results {
  font-size: 24px;
  font-weight: 700;
  line-height: 16px;
  padding: 17px 33px;
  box-shadow: 0px 4px 4px 0px #00000040;
  transition: background-color 0.5s ease;
  margin: 0 0 25px;
}
.leaderboard-group .button-update-results.clicked {
  background: #5C91F5;
}
.instructor-insights-selects {
  width: 196px;
  display: flex;
  flex-direction: column;
  gap: 9px;
}
.dashboard-timer {
  margin: 25px 0 53px;
  font-family: 'Inter', sans-serif;
  font-size: 64px;
  font-weight: 600;
  line-height: 96px;
  color: #fff;
  padding: 7px 40px;
  background: #3A507B;
}
.dashboard-timer {
  width: 270px;
  text-align: center;
}

.leaderboard {
  box-shadow: 0px 4px 4px 0px #00000040;
  font-family: 'Inter', sans-serif;
  min-width: 750px;
  border-radius: 8px;
}
.leaderboard-header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 13px 50px 10px;
  background: #2A4169;
  position: relative;
}
.leaderboard-header h2 {
  margin: 0;
  font-family: 'Inter';
  font-size: 34.32px;
  font-weight: 700;
  line-height: 51.48px;
  text-align: center;
  color: #fff;
}
.leaderboard-header h2 .small {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.leaderboard-header .select-wrapper {
  position: absolute;
  left: 4px;
  bottom: 11px;
}
.leaderboard-body {
  background: #D7F0F2;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 25px 17px 68px;
  position: relative;
}
.leaderboard-body .button-sort {
  position: absolute;
  z-index: 1;
  top: 13px;
  left: 17px;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #2A4169;
  padding: 0;
  display: flex;
}
.leaderboard-body .button-sort:after {
  display: block;
  content: '';
  background: url('./images/icon-sorting-arrows.svg') no-repeat center center;
  width: 19px;
  height: 19px;
}
.leaderboard-body .button-sort.active:after {
  transform: scale(-1, 1);
}
.leaderboard-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 12px;
}
.leaderboard-buttons button,
.leaderboard-buttons a {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  padding: 0;
  border-bottom: 1px solid transparent;
  text-decoration: none;
}
.leaderboard-body-buttons {
  column-gap: 30px;
  margin-bottom: 24px;
  position: absolute;
  top: 12px;
  width: 100%;
}
.leaderboard-body-buttons button {
  color: #2A4169;
}
.leaderboard-body-buttons button.active {
  border-color: #2A4169;
}
.leaderboard-body-buttons button:disabled {
  opacity: 0.5;
}
.leaderboard-loader {
  border: 4px solid #2A4169;
  border-top: 4px solid transparent;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0 0 24px;
}