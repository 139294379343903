.game-header {
  background: #ffffff;
  display: flex;
  padding: 12px 25px;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #D5D9DD;
  position: relative;
  min-height: 64px;
  position: relative;
  z-index: 1;
}

.game-header-left-part {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 26px;
}
.game-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.game-logo img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.game-logo span {
  font-family: 'Mukta Mahee';
  font-size: 17px;
  font-weight: 700;
  line-height: 25px;
  color: #2F4166;
}

.game-header-columns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.game-header-right-part {
  position: absolute;
  right: 43px;
  display: flex;
  align-items: center;
}

.home-link {
  margin-right: 14px;
  display: flex;
}

.provide-feedback-link {
  margin-right: 8px;
  display: flex;
}

.header-username {
  font-family: "Mukta Mahee";
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: #2F4166;
  text-decoration: underline;
  text-decoration-thickness: .25px;
  margin-right: 16px;
}

.sound-status {
  position: relative;
  z-index: 10;
  padding: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 11px;
}

.game-header .button-logout {
  color: #2F4166;
}