.video-wrapper {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.intro-video {
  width: 100%;
  display: block;
  border-radius: 5px;
  cursor: pointer;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease;
  cursor: pointer;
  border-radius: 5px;
}

.video-overlay.hidden {
  opacity: 0;
  pointer-events: none;
}

.video-wrapper:hover .video-overlay {
  opacity: 1;
}

.play-pause-button {
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  z-index: 2;
  background: #2A4169;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 0;
}
