.main-section-sorting {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.main-section-sorting main.content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sorting-company-logo {
  max-width: 300px;
}
.game-footer {
  height: 160px;
  border-top: 2px solid #D5D9DD;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-view-summary {
  margin-top: 26px;
}

.summary-block-container {
  width: 600px;
  margin: 0 auto;
  font-family: 'Mukta Vaani';
}
.summary-page-title {
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  color: #5C91F5;
  margin: 0 0 8px;
}
.summary-page-description {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin: 0 0 16px;
  color: rgba(0, 27, 59, 0.6);
}
.summary-block {
  padding: 20px;
  background: #DBB7FF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  position: relative;
}
.summary-block:before,
.summary-block:after {
  display: block;
  content: '';
  position: absolute;
  top: 0;
}
.summary-block:before {
  width: 157px;
  height: 136px;
  left: 0;
  background: url('./images/summary-flags-1.png') no-repeat center center;
  background-size: 157px 136px;
}
.summary-block:after {
  width: 124px;
  height: 141px;
  right: 0;
  background: url('./images/summary-flags-2.png') no-repeat center center;
  background-size: 124px 141px;
}
.summary-block-item {
  padding: 40px;
  border-radius: 4px;
  background: #9B6FFF;
}
.summary-block-item:second-child {
  padding: 16px;
}
h3.summary-level {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #fff;
  margin: 0 0 20px;
}
.summary-rank {
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  margin: 0 0 32px;
}

.summary-stars-rating {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 40px;
}
.summary-star-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 8px;
}
.summary-star-block img {
  width: 64px;
  height: 64px;
}
.summary-star-value {
  font-size: 21px;
  font-weight: 700;
  line-height: 28px;
  color: #fff;
}

h3.summary-score {
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  color: #fff;
  text-align: center;
  margin: 0 0 10px;
}
.summary-best-score {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #fff;
  text-align: center;
  margin: 0;
}

.summary-next-button {
  margin-top: 80px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  border-radius: 100px;
  padding: 8px;
  min-width: 160px;
}

.badge-page-title {
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  color: #5C91F5;
  margin: 0 0 8px;
  text-align: center;
}
.badge-page-description {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin: 0 0 38px;
  color: rgba(0, 27, 59, 0.6);
  text-align: center;
}
.badge-container {
  position: relative;
}
.badge-info {
  position: absolute;
  top: 115px;
  text-transform: uppercase;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  max-width: 500px;
  color: #fff;
  font-family: 'Inter';
}
h3.badge-game-type {
  font-size: 38px;
  font-weight: 700;
  line-height: 44px;
  margin-bottom: 87px;
}
h2.badge-rank {
  font-size: 48px;
  font-weight: 700;
  line-height: 48px;
}